import { ContainerFee } from './ContainerFee'
import { Identity } from './Identity'
import { ModelBase } from './ModelBase'

export class TransportationFee extends ModelBase {
  constructor (raw = {}, cache = {}) {
    super(raw, cache)

    const r11s = raw.relationships || {}

    const containerFees = r11s.containerFees || []
    this.containerFees = containerFees.map(item => new ContainerFee(item))

    const identity = ModelBase.modelFromCache(Identity, r11s.identity, cache)
    this.identityId = identity.id
    this.identityFullName = identity.fullName
    this.identityEmail = identity.email
    this.identityAvatarLink = identity.avatarLink
  }
}
