import { feesMutations } from './types'

export default {
  [feesMutations.SET_IS_LOADING] (state, value) {
    state.isLoading = value
  },

  [feesMutations.SET_IS_NEXT_LOADING] (state, value) {
    state.isNextLoading = value
  },

  [feesMutations.SET_DEPARTURE_PORTS] (state, value) {
    state.departurePorts = value
  },

  [feesMutations.SET_LIST] (state, value) {
    state.list = value
  },

  [feesMutations.PUSH_LIST] (state, value) {
    state.list = state.list.concat(value)
  },

  [feesMutations.SET_PAGE_NUMBER] (state, value) {
    state.pageNumber = value
  },

  [feesMutations.SET_SELECTED_ITEM_ID] (state, value) {
    state.selectedItemId = value
  }
}
