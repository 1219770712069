import { ModelBase } from './ModelBase'

export class ContainerFee extends ModelBase {
  constructor (raw = {}, cache = {}) {
    super(raw, cache)

    this.amount = this._num(raw.amount)
    this.currency = this._str(raw.currency)
    this.departurePort = this._str(raw.departurePort)
    this.destinationCountry = this._str(raw.destinationCountry)
    this.destinationPort = this._str(raw.destinationPort)
  }
}
