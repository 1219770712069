export const feesActions = {
  UPDATE_TRANSPORTATION_FEES: 'UPDATE_TRANSPORTATION_FEES',

  LOAD_DEPARTURE_PORTS: 'LOAD_DEPARTURE_PORTS',
  LOAD_LIST: 'LOAD_LIST',
  LOAD_MORE: 'LOAD_MORE',
  SET_SELECTED_ITEM_ID: 'SET_SELECTED_ITEM_ID',
  SET_IS_LOADING: 'SET_IS_LOADING'
}

export const feesMutations = {
  SET_IS_LOADING: 'SET_IS_LOADING',
  SET_IS_NEXT_LOADING: 'SET_IS_NEXT_LOADING',
  SET_DEPARTURE_PORTS: 'SET_DEPARTURE_PORTS',
  SET_LIST: 'SET_LIST',
  PUSH_LIST: 'PUSH_LIST',
  SET_PAGE_NUMBER: 'SET_PAGE_NUMBER',
  SET_SELECTED_ITEM_ID: 'SET_SELECTED_ITEM_ID',
}

export const feesGetters = {
  IS_LOADING: 'IS_LOADING',
  IS_NEXT_LOADING: 'IS_NEXT_LOADING',
  DEPARTURE_PORTS: 'DEPARTURE_PORTS',
  LIST: 'LIST',
  SELECTED_ITEM: 'SELECTED_ITEM',
  PAGE_NUMBER: 'PAGE_NUMBER',
}
