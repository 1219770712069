import { enums } from '@shelf.network/js-sdk'
import { FEES_URL_PARAM_TABS } from 'Constants/feesUrlParams'

export const DEFAULT_FEE_CURRENCY = 'USD'
export const DESTINATION_COUNTRIES = Object.freeze({
  GE: 'GE',
  UA: 'UA',
  AZ: 'AZ'
})

export const DESTINATION_PORTS = Object.freeze({
  [DESTINATION_COUNTRIES.GE]: 'Poti',
  [DESTINATION_COUNTRIES.UA]: 'Odessa',
  [DESTINATION_COUNTRIES.AZ]: 'Baku'
})

export const FEES_LIST_TEMPLATES = Object.freeze({
  general: 'general',
  users: 'users',
})

export const FEE_LEVEL_TO_FEES_TAB_MAP = Object.freeze({
  [enums.feeLevels.platform]: FEES_URL_PARAM_TABS.general,
  [enums.feeLevels.identity]: FEES_URL_PARAM_TABS.users,
})

export const FEES_PAGE_LIMIT = 15
