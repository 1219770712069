export function genFeesState () {
  return {
    isLoading: true,
    isNextLoading: false,
    departurePorts: [],
    list: [],
    pageNumber: 0,
    selectedItemId: ''
  }
}

export default genFeesState
