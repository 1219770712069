import { feesActions, feesGetters, feesMutations } from './types'
import { FEES_PAGE_LIMIT } from '../constants'

import { LoneSdkCall } from 'Utils/LoneSdkCall'
import { sdk } from 'Services/shelfNetworkSdk'

const loneSdkCall = new LoneSdkCall()

export default {
  async [feesActions.LOAD_DEPARTURE_PORTS] ({ commit }) {
    const { data: ports } = await sdk.copartBackOffice.getTransportationPorts()
    commit(feesMutations.SET_DEPARTURE_PORTS, ports)
  },

  async [feesActions.LOAD_LIST] ({ commit }, opts) {
    commit(feesMutations.SET_IS_LOADING, true)

    const { data: fees } = await loneSdkCall.takeLatest(
      sdk.copartBackOffice.getTransportationFees({
        query: createFeesQuery(opts)
      })
    )

    commit(feesMutations.SET_LIST, fees)
    commit(feesMutations.SET_PAGE_NUMBER, 0)

    commit(feesMutations.SET_IS_LOADING, false)
  },

  async [feesActions.LOAD_MORE] ({ commit, getters }, opts) {
    if (getters[feesGetters.PAGE_NUMBER] === null) return

    commit(feesMutations.SET_IS_NEXT_LOADING, true)

    const pageNumber = getters[feesGetters.PAGE_NUMBER] + 1
    const { data: fees } = await loneSdkCall.takeLatest(
      sdk.copartBackOffice.getTransportationFees({
        query: createFeesQuery({
          ...opts,
          page: { number: pageNumber }
        })
      })
    )

    commit(feesMutations.PUSH_LIST, fees)
    commit(
      feesMutations.SET_PAGE_NUMBER,
      fees.length < FEES_PAGE_LIMIT ? null : pageNumber
    )

    commit(feesMutations.SET_IS_NEXT_LOADING, false)
  },

  async [feesActions.UPDATE_TRANSPORTATION_FEES] (_, opts) {
    await sdk.copartBackOffice.setTransportationFees(opts)
  },

  [feesActions.SET_SELECTED_ITEM_ID] ({ commit }, id) {
    commit(feesMutations.SET_SELECTED_ITEM_ID, id)
  },

  [feesActions.SET_IS_LOADING] ({ commit }, value) {
    commit(feesMutations.SET_IS_LOADING, value)
  }
}

function createFeesQuery (opts) {
  return {
    ...opts,
    page: {
      ...(opts.page || {}),
      limit: FEES_PAGE_LIMIT
    },
    include: ['container_fee', 'identity', 'identity.basics']
  }
}
