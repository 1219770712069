import { TransportationFee } from 'Models/TransportationFee'
import { feesGetters } from './types'

export default {
  [feesGetters.IS_LOADING]: state => state.isLoading,
  [feesGetters.IS_NEXT_LOADING]: state => state.isNextLoading,
  [feesGetters.PAGE_NUMBER]: state => state.pageNumber,

  [feesGetters.DEPARTURE_PORTS]: state => state.departurePorts,
  [feesGetters.LIST]: state => state.list
    .map(item => new TransportationFee(item)),
  [feesGetters.SELECTED_ITEM]: (state, getters) =>
    getters[feesGetters.LIST].find(item => item.id === state.selectedItemId),
}
